export const Equipe = () => {
  return (
    <section id="team" className="team">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Nossa Equipe</h2>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="pic">
                <img
                  src="/assets/img/team/team-1.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Evandro Carlos da Silva</h4>
                <span>Coordenador</span>
                <p>
                  Profissional de Educação Física e Mestre de Taekwondo 6°dan
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4 mt-lg-0">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="pic">
                <img
                  src="/assets/img/team/team-2.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Rafael Augusto</h4>
                <span>Pofessor de Taewkondo 1</span>
                <p>
                  Profissional de Educação Física - Professor de Taekwondo 2°
                  dan.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="pic">
                <img
                  src="/assets/img/team/team-3.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Eduardo Kauan</h4>
                <span>Pofessor de Taekwono 2</span>
                <p>Cursando Edução Física</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div className="pic">
                <img
                  src="/assets/img/team/team-4.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Marília Corsi</h4>
                <span>Fisioterapeuta</span>
                <p>Graduada em Fisioterapia</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div className="pic">
                <img
                  src="/assets/img/team/team-4.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Milene Da Costa Moreira</h4>
                <span>Coordenadora</span>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div className="pic">
                <img
                  src="/assets/img/team/team-4.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Ana Paula de Jesus da Silva</h4>
                <span>Monitora</span>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div className="pic">
                <img
                  src="/assets/img/team/team-4.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Ana Laura</h4>
                <span>Jovem Aprendiz</span>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div className="pic">
                <img
                  src="/assets/img/team/team-4.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Gustavo Guilherme</h4>
                <span>Monitor</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
